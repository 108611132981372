<template>
  <div class="survey survey-memo">
    <b-form>
      <p class="survey-memo-title">{{ title }}</p>
      <b-form-textarea rows="6" v-model="q.answer[0].data"></b-form-textarea>
    </b-form>
  </div>
</template>
<script>
export default {
  name: 'Q99',
  model: {
    prop: 'q',
  },
  props: ['q'],
  data() {
    return {
      title: '메모',
    };
  },
  updated() {
    if (this.q === '') {
      this.q = {
        title: '메모',
        answer: [
          {
            data: '',
          },
        ],
      };
    }
  },
};
</script>